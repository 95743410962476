import React, { useEffect } from "react";
import { StyledAlertEditBox } from "../styles/AlertEditBox";

function AlertEditBox({
  label,
  list,
  showList,
  setShowList,
  valueVariable,
  setValueVariable,
  tittle,
  setCity,
  refName,
}) {
  function handleChoose(item) {
    setValueVariable(item);
    setShowList(false);
    if (tittle === "Estado") {
      setCity("");
    }
  }

  function onFocusInput() {
    if (setShowList) {
      setShowList(true);
    }
  }

  return (
    <StyledAlertEditBox ref={refName}>
      <div className="flexLabelInput">
        <label>{label}</label>
        <input
          type="text"
          onFocus={onFocusInput}
          value={valueVariable}
          onChange={(e) => setValueVariable(e.target.value)}
        />
      </div>
      <ul>
        {showList &&
          list.map((item, index) => (
            <li onClick={() => handleChoose(item)} key={index}>
              {item}
            </li>
          ))}
      </ul>
    </StyledAlertEditBox>
  );
}

export default AlertEditBox;
