import { StyledAbout } from "../styles/About";
import historia from "../assets/historia.png";
import ReactGA from "react-ga4";
// import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

ReactGA.initialize("G-9EJ6PS0PBE");

function About({ setUrlAtual }) {
  // const [renderizacao, setRenderizacao] = useState(null);
  // Variáveis para consumo da API
  // const Token = process.env.REACT_APP_TOKEN;
  // const URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  // let queryForApi = `${URL}/analytics/analytics/`;

  // Lógica pra capturar a página antes do login
  useEffect(() => {
    setUrlAtual(location.pathname);
  }, [location.pathname, setUrlAtual]);

  // useEffect(() => {
  //   async function requisicao(apiLink) {
  //     fetch(apiLink, {
  //       headers: {
  //         Authorization: `Token ${Token}`,
  //       },
  //     })
  //       .then((res) => {
  //         return res.json();
  //       })
  //       .then((response) => {
  //         setRenderizacao(response);
  //       });
  //   }

  //   // requisicao(queryForApi);
  // }, [queryForApi, Token]);

  const pageTittle = "Sobre - Alô Concursos";
  const pageDescription =
    "Conheça um pouco mais sobre a nossa equipe e nosso trabalho.";
  const pageLink = `https://www.aloconcursos.com/sobre`;

  return (
    <StyledAbout className="About">
      <Helmet>
        <title>{pageTittle}</title>
        <meta property="og:title" content={pageTittle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageLink} />
        <meta name="twitter:title" content={pageTittle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:url" content={pageLink} />
      </Helmet>
      <section className="firstSection">
        <div className="ourHistory">
          <div className="flexTittleOurHistory">
            <h2>Um pouco da nossa história</h2>
            <p>
              A Alô Concursos é uma iniciativa de trabalhadores na área de
              tecnologia cansados das experiências deficientes oferecidas pelas
              soluções existentes para informações de concursos públicos.
              Frequentemente, em nossas buscas por informações sobre novos
              certames, nos deparávamos com designs desagradáveis, cobertura
              incompleta e usabilidade confusa.
              <br />
              <br />
              Combinando nossas habilidades e determinação, fundamos esta
              empresa com um propósito claro: oferecer uma plataforma que
              transcenda todas essas limitações. Assim nasceu o Alô Concursos,
              uma nova abordagem para informação de qualidade, design intuitivo
              e cobertura completa. Nossa jornada é alimentada pela busca
              incessante pela excelência e pelo compromisso de servir à
              comunidade de concurseiros com o que há de melhor em recursos e
              suporte. Estamos ansiosos para compartilhar essa jornada com
              vocês, ajudando a transformar suas aspirações em realidade.
            </p>
          </div>
          <img
            src={historia}
            alt="Grupo de designers e programadores interagindo"
          />
        </div>
      </section>
    </StyledAbout>
  );
}

export default About;
