import styled from "styled-components";

export const StyledAlerta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  /* 1 Seção */
  .alertFlexTittleAndButtonMain {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .alertFlexTittleAndButtonMainTittle {
      display: flex;
      flex-direction: column;
      width: 619px;
      gap: 8px;

      h2 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -0.96px;
      }
      p {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;

        span {
          color: var(--Grey-500, #606780);
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.16px;
        }
      }
    }
    button {
      color: #fff;
      text-align: center;
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.16px;
      border-radius: var(--Radius-md, 8px);
      background: var(--Primary-500, #1b2cc1);
      display: flex;
      height: 48px;
      padding: 12px 24px;
      align-items: center;
      gap: var(--Scale-2, 16px);
      width: 138px;
      border: none;
      cursor: pointer;
    }
  }
  /* 2 Seção */
  .alertBoxForm {
    display: flex;
    padding: 64px 32px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--Supporting-white, #fff);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    .alertBoxFormTittle {
      display: flex;
      width: 605px;
      flex-direction: column;
      gap: 24px;
      h2 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;
      }
      p {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
        span {
          color: var(--Grey-500, #606780);
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.16px;
        }
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 24px;

      hr {
        height: 1px;
        background-color: #d9d9d9;
        border: none;
      }
      .flexInputs {
        display: flex;
        gap: 36px;
      }
    }
    .flexButtons {
      display: flex;
      gap: 24px;
      button {
        cursor: pointer;
        width: 129px;
        display: flex;
        padding: 12px 24px;
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
      }
      .salvar {
        background: var(--Primary-500, #1b2cc1);
        color: #fff;
        border: none;
      }
      .fechar {
        border: 1px solid var(--Primary-500, #1b2cc1);
        color: var(--Primary-500, #1b2cc1);
        background-color: transparent;
      }
      .salvarDisabled {
        border: none;
        cursor: not-allowed;
        display: flex;
        padding: 12px 24px;
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        background: var(--Grey-200, #c5c8db);
        color: #fff;
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
      .fecharDisabled {
        cursor: not-allowed;
        display: flex;
        padding: 12px 24px;
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        border: 1px solid var(--Grey-200, #c5c8db);
        color: var(--Grey-200, #c5c8db);
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
  }
  .alertCreatedBox {
    display: flex;
    flex-direction: column;
    gap: 32px;

    h2 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.72px;
    }
  }

  @media (max-width: 1404px) {
    .alertBoxForm {
    }
    form {
      .flexInputs {
        flex-direction: column;
        gap: 24px;
      }
    }
  }

  @media (max-width: 1244px) {
    .alertBoxForm {
      .alertBoxFormTittle {
        width: 100%;
      }
    }
  }

  @media (max-width: 774px) {
    .alertBoxForm {
      padding: 40px 16px;
    }
    .alertFlexTittleAndButtonMain {
      .alertFlexTittleAndButtonMainTittle {
        width: 100%;
      }
    }
  }
`;
