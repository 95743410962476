import styled from "styled-components";

export const StyledFormLabelInputAlert = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 352px;
  label {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
  }
  span {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.16px;
  }

  .inputAndError {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 352px;

    input {
      width: 100%;
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      border: 1px solid var(--Primary-500, #1b2cc1);
      background: var(--Grey-50, #f7f8ff);
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    span {
      color: var(--Error-500, #d41e21);

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    li {
      padding: 8px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      font-family: Work Sans;
    }
  }

  @media (max-width: 774px) {
    width: 100%;
    min-width: 264px;
  }
  @media (max-width: 435px) {
    .inputAndError {
      width: 100%;
    }
  }
`;
