import styled from "styled-components";

export const StyledAlertEditBox = styled.div`
  .flexLabelInput {
    display: flex;
    flex-direction: column;
    gap: 4px;

    label {
      color: var(--Grey-500, #606780);
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }
    input {
      width: 100%;
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      border: 1px solid var(--Primary-500, #1b2cc1);
      background: var(--Grey-50, #f7f8ff);
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    li {
      padding: 8px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      font-family: Work Sans;
    }
  }
`;
