import React from "react";
import { StyledBlogHomepage } from "../styles/BlogHomepage";
import BlogCard from "../components/BlogCard";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBox from "../components/SearchBox";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function BlogHomepage({ setUrlAtual, setTermoPesquisado, setSearchReset }) {
  const [renderizacao, setRenderizacao] = useState(null);
  const [idMainPost, setIdMainPost] = useState(null);
  // * Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  const location = useLocation();

  useEffect(() => {
    async function requisicao(apiLink) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setRenderizacao(response);
        });
    }

    setUrlAtual(location.pathname);
    requisicao(`${URL}/post/post/`);
  }, [URL, location.pathname, setUrlAtual, Token]);

  useEffect(() => {
    setIdMainPost(renderizacao?.results[0].id);
  }, [renderizacao]);

  const pageTittle = "Portal da Alô Concursos";
  const pageDescription =
    "Confira os últimos posts no portal da Alô Concursos.";
  const pageLink = `https://www.aloconcursos.com/noticias`;

  return (
    <StyledBlogHomepage>
      <Helmet>
        <title>{pageTittle}</title>
        <meta property="og:title" content={pageTittle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageLink} />
        <meta name="twitter:title" content={pageTittle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:url" content={pageLink} />
      </Helmet>
      <section className="firstSection">
        <div className="boxTittle">
          <h1>Portal da Alô Concursos</h1>
          <p>Fique atualizado sobre o mundo dos concursos público</p>
        </div>

        {renderizacao && (
          <Link
            style={{ textDecoration: "none" }}
            to={`/noticias/${renderizacao.results[0].slug}`}
            className="mainPost"
          >
            <img
              src={renderizacao.results[0].images[0].url}
              alt={renderizacao.results[0].images[0].caption}
            />
            <div className="tittleAndText">
              <h2>{renderizacao.results[0].title}</h2>
              <p>28 de novembro de 2024</p>
            </div>
          </Link>
        )}

        <div className="posts">
          <h2>Últimos artigos</h2>
          <div className="gridCards">
            {renderizacao?.results.map((item, index) => {
              if (idMainPost === item.id) {
                return null;
              }
              return <BlogCard key={index} {...item} />;
            })}
          </div>
        </div>
        {/* <div className="loadMore">
          <button>
            <p>Carregar mais</p>
          </button>
        </div> */}
      </section>
      <SearchBox
        setTermoPesquisado={setTermoPesquisado}
        setSearchReset={setSearchReset}
      />
    </StyledBlogHomepage>
  );
}

export default BlogHomepage;
