import styled from "styled-components";

export const StyledAlertMiniBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  span {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.16px;
  }
  p {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
  }
`;
