import React, { useState, useEffect, useRef } from "react";
import { StyledAlertCreatedCard } from "../styles/AlertCreatedCard";
import { conversaoData } from "../tools/functions";
import AlertMiniBox from "./AlertMiniBox";
import trashxmark from "../assets/trashxmark.svg";
import fileEdit from "../assets/fileEdit.svg";
import angledown from "../assets/angledown.svg";
import concluidoImg from "../assets/concluidoImg.png";
import AlertEditBox from "./AlertEditBox";
import { estadosBrasil } from "../tools/objects";
import { siglasEstados } from "../tools/objects";

function AlertCreatedCard({
  id,
  name,
  created_datetime,
  contest_tag_name,
  office_tag_name,
  location_name,
  recarregar,
  setRecarregar,
}) {
  const URL = process.env.REACT_APP_API_URL;
  const Token = process.env.REACT_APP_TOKEN;

  const estadosPorSigla = estadosBrasil.reduce((acc, estado, index) => {
    acc[siglasEstados[index]] = estado;
    return acc;
  }, {});
  // Variávies que seguram os valores que serão enviados para o backend para edição
  const [miniBoxName, setMiniBoxName] = useState(name);
  const [miniBoxOffice, setMiniBoxOffice] = useState(office_tag_name);
  const [miniBoxContest, setMiniBoxContest] = useState(contest_tag_name);
  const [miniBoxState, setMiniBoxState] = useState(
    estadosPorSigla[location_name?.slice(-2)]
  );
  const [miniBoxCity, setMiniBoxCity] = useState(location_name);

  // Variável que controla se esta no modo de edição ou não
  const [editMode, setEditMode] = useState(false);

  // Variáveis que controlam o conteúdo da lista de opções
  const [officeListContent, setOfficeListContent] = useState([]);
  const [contestListContent, setContestListContent] = useState([]);
  const [stateListContent, setStateListContent] = useState(estadosBrasil);
  const [cityListContent, setCityListContent] = useState([]);

  // Lógica de pesquisa em campos do formulário
  const [filteredOffice, setFilteredOffice] = useState([]);
  const [filteredContest, setFilteredContest] = useState([]);
  const [filteredState, setFilteredState] = useState(stateListContent);
  const [filteredCity, setFilteredCity] = useState([]);

  // Variáveis que controlam a exibição das listas
  const [showListOffice, setShowListOffice] = useState(false);
  const [showListContest, setShowListContest] = useState(false);
  const [showListState, setShowListState] = useState(false);
  const [showListCity, setShowListCity] = useState(false);

  // Controle de remoção de lista ao clicar fora
  const refBoxOffice = useRef(null);
  const refBoxContest = useRef(null);
  const refBoxState = useRef(null);
  const refBoxCity = useRef(null);

  // Controle para mostrar ou não o card
  const [showCard, setShowCard] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [showCard]);

  const officeOutside = (event) => {
    if (refBoxOffice.current && !refBoxOffice.current.contains(event.target)) {
      setShowListOffice(false);
    }
  };
  const contestOutside = (event) => {
    if (
      refBoxContest.current &&
      !refBoxContest.current.contains(event.target)
    ) {
      setShowListContest(false);
    }
  };

  const stateOutside = (event) => {
    if (refBoxState.current && !refBoxState.current.contains(event.target)) {
      setShowListState(false);
    }
  };
  const cityOutside = (event) => {
    if (refBoxCity.current && !refBoxCity.current.contains(event.target)) {
      setShowListCity(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", officeOutside);
    return () => {
      document.removeEventListener("mousedown", officeOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", contestOutside);
    return () => {
      document.removeEventListener("mousedown", contestOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", stateOutside);
    return () => {
      document.removeEventListener("mousedown", stateOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", cityOutside);
    return () => {
      document.removeEventListener("mousedown", cityOutside);
    };
  }, []);

  async function sendDelete() {
    await fetch(`${URL}/alerts/alerts/${id}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${Token}`,
      },
    }).then((response) => {
      if (response.ok) {
        setRecarregar(!recarregar);
      }
    });
  }

  async function sendEdit() {
    const formData = {
      name: miniBoxName,
      office_tag_name: miniBoxOffice,
      contest_tag_name: miniBoxContest,
      location_name: miniBoxCity,
    };

    await fetch(`${URL}/alerts/alerts/${id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Token ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (response.ok) {
        setRecarregar(!recarregar);
        setEditMode(false);
      }
    });
  }

  // Buscando opções de office e contest
  useEffect(() => {
    async function listRequisition(endpoint, type) {
      await fetch(endpoint, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "office") {
            setOfficeListContent(response.map((item) => item.name));
          }
          if (type === "contest") {
            setContestListContent(response.map((item) => item.name));
          }
        });
    }

    listRequisition(`${URL}/office/top-tags/`, "office");
    listRequisition(`${URL}/contest/top-tags/`, "contest");
  }, [Token, URL]);

  // Efeitos que ativam quando o estado é selecionado
  useEffect(() => {
    async function getListContentByState(endpoint) {
      await fetch(endpoint, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setCityListContent(response.results.map((item) => item.name));
          setFilteredCity(response.results.map((item) => item.name));
        });
    }

    function findStateShortName(state) {
      const index = estadosBrasil.indexOf(state);

      if (index !== -1) {
        return siglasEstados[index];
      }
    }

    let stateGet = findStateShortName(miniBoxState);

    getListContentByState(
      `${URL}/location/location/?limit=1000&state=${stateGet}`
    );
  }, [miniBoxState, URL, Token]);

  useEffect(() => {
    if (miniBoxOffice === "") {
      setFilteredOffice(officeListContent);
    } else {
      setFilteredOffice(
        officeListContent.filter(
          (item) =>
            item &&
            miniBoxOffice &&
            item.toLowerCase().includes(miniBoxOffice.toLowerCase())
        )
      );
    }
  }, [miniBoxOffice, officeListContent]);

  useEffect(() => {
    if (miniBoxContest === "") {
      setFilteredContest(contestListContent);
    } else {
      setFilteredContest(
        contestListContent.filter(
          (item) =>
            item &&
            miniBoxContest &&
            item.toLowerCase().includes(miniBoxContest.toLowerCase())
        )
      );
    }
  }, [miniBoxContest, contestListContent]);

  useEffect(() => {
    if (miniBoxState === "") {
      setFilteredState(stateListContent);
    } else {
      setFilteredState(
        stateListContent.filter(
          (item) =>
            item &&
            miniBoxState &&
            item.toLowerCase().includes(miniBoxState.toLowerCase())
        )
      );
    }
  }, [miniBoxState, stateListContent]);

  useEffect(() => {
    if (miniBoxCity === "") {
      setFilteredCity(cityListContent);
    } else {
      setFilteredCity(
        cityListContent.filter(
          (item) =>
            item &&
            miniBoxCity &&
            item.toLowerCase().includes(miniBoxCity.toLowerCase())
        )
      );
    }
  }, [miniBoxCity, cityListContent]);

  return (
    <StyledAlertCreatedCard>
      <div className="tittle">
        {editMode ? (
          <input
            className="inputAlertNameCard"
            value={miniBoxName}
            onChange={(e) => setMiniBoxName(e.target.value)}
            type="text"
          />
        ) : (
          <h3>{name && name}</h3>
        )}

        <p>Última atualização: {conversaoData(created_datetime)}</p>
        <button onClick={() => setShowCard(!showCard)}>
          <img
            style={showCard ? { transform: "rotate(-90deg)" } : null}
            src={angledown}
            alt="Seta"
          />
        </button>
      </div>
      <div
        ref={contentRef}
        style={showCard ? { height: contentHeight } : { height: "0px" }}
        className="flexAlertCardShowOrNot"
      >
        <h2>Filtros do alerta</h2>
        <div
          style={
            office_tag_name && contest_tag_name && location_name
              ? { width: "100%" }
              : { width: "60%" }
          }
          className="flexAlertCardShowOrNotFlexMiniBox"
        >
          {office_tag_name ? (
            editMode ? (
              <AlertEditBox
                label={"Etiquetas de cargos"}
                list={filteredOffice}
                refName={refBoxOffice}
                setList={setFilteredOffice}
                valueVariable={miniBoxOffice}
                setValueVariable={setMiniBoxOffice}
                showList={showListOffice}
                setShowList={setShowListOffice}
              />
            ) : (
              <AlertMiniBox
                tittle={"Etiquetas de cargos"}
                subTittle={office_tag_name}
              />
            )
          ) : null}
          {contest_tag_name ? (
            editMode ? (
              <AlertEditBox
                label={"Etiquetas de concursos"}
                refName={refBoxContest}
                list={filteredContest}
                setList={setFilteredContest}
                valueVariable={miniBoxContest}
                setValueVariable={setMiniBoxContest}
                showList={showListContest}
                setShowList={setShowListContest}
              />
            ) : (
              <AlertMiniBox
                tittle={"Etiquetas de concursos"}
                subTittle={contest_tag_name}
              />
            )
          ) : null}
          {location_name ? (
            editMode ? (
              <>
                <AlertEditBox
                  tittle={"Estado"}
                  valueVariable={miniBoxState}
                  setValueVariable={setMiniBoxState}
                  label={"Estados"}
                  refName={refBoxState}
                  list={filteredState}
                  setList={setFilteredState}
                  showList={showListState}
                  setCity={setMiniBoxCity}
                  setShowList={setShowListState}
                />
                <AlertEditBox
                  label={"Cidades"}
                  refName={refBoxCity}
                  valueVariable={miniBoxCity}
                  setValueVariable={setMiniBoxCity}
                  list={filteredCity}
                  setList={setFilteredCity}
                  setCity={setMiniBoxCity}
                  showList={showListCity}
                  setShowList={setShowListCity}
                />
              </>
            ) : (
              <AlertMiniBox tittle={"Cidades"} subTittle={location_name} />
            )
          ) : null}
        </div>
        <hr />
        <div className="flexAlertCardShowOrNotFlexButtons">
          {editMode ? null : (
            <button onClick={() => sendDelete()} className="delete">
              <img src={trashxmark} alt="ícone de uma lixeira" />
              <p>Deletar</p>
            </button>
          )}
          {editMode ? (
            <button className="concluir" onClick={() => sendEdit()}>
              <img
                style={{ width: "16px" }}
                src={concluidoImg}
                alt="Imagem de um v"
              />
              <p>Salvar</p>
            </button>
          ) : (
            <button className="edit" onClick={() => setEditMode(true)}>
              <img src={fileEdit} alt="ícone de um lápis" />
              <p>Editar</p>
            </button>
          )}
        </div>
      </div>
    </StyledAlertCreatedCard>
  );
}

export default AlertCreatedCard;
