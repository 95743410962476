import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Mousewheel } from "swiper/modules";
import CardSlide from "./CardSlide";

function SlideSwiper({ renderizacao, setRecarregarComTag, recarregarComTag }) {
  return (
    <Swiper
      className="swiper-container"
      modules={[Navigation, Autoplay, Mousewheel]}
      initialSlide={1}
      navigation={true}
      loop={true}
      slidesPerView={4}
      spaceBetween={49}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      observer={true}
      breakpoints={{
        0: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
        },

        922: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        930: { slidesPerView: 2, spaceBetween: 50 },
        1054: { slidesPerView: 2, spaceBetween: 100 },
        1176: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        1500: {
          slidesPerView: 3,
          spaceBetween: 110,
        },
        1578: {
          slidesPerView: 3,
          spaceBetween: 130,
        },
        1610: {
          slidesPerView: 3,
          spaceBetween: 150,
        },
        1697: {
          slidesPerView: 3,
          spaceBetween: 165,
        },
        1720: {
          slidesPerView: 3,
          spaceBetween: 210,
        },
        1803: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1884: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1905: {
          slidesPerView: 4,
          spaceBetween: 49,
        },
      }}
    >
      {renderizacao
        ? renderizacao.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <CardSlide key={index} {...item} />
              </SwiperSlide>
            );
          })
        : null}
    </Swiper>
  );
}

export default SlideSwiper;
