import React, { useState, useEffect, useRef } from "react";
import { StyledAlerta } from "../styles/Alerta";
import cross from "../assets/cross.svg";
import disk from "../assets/disk.svg";
import FormLabelInputAlert from "../components/FormLabelInputAlert";
import AlertCreatedCard from "../components/AlertCreatedCard";
import { estadosBrasil } from "../tools/objects";
import { siglasEstados } from "../tools/objects";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Alerta({ setUrlAtual, tagAlerta, tagType }) {
  const URL = process.env.REACT_APP_API_URL;
  const Token = process.env.REACT_APP_TOKEN;

  // Variável para recarregar o card de alerta criado
  const [recarregar, setRecarregar] = useState(false);

  // Variaveis que seguram o valor dos inputs
  const [name, setName] = useState("");
  const [office, setOffice] = useState(tagType === "cargo" ? tagAlerta : "");
  const [contest, setContest] = useState(
    tagType === "concurso" ? tagAlerta : ""
  );
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  // Variáveis de erros
  const [nameError, setNameError] = useState(false);
  const [officeError, setOfficeError] = useState(false);
  const [contestError, setContestError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);

  // Mensagens de erros
  const nameErrorMessage = "* É necessário um nome válido para o alerta.";
  const officeErrorMessage =
    "* É necesario um cargo, um concurso ou um estado e cidade para o alerta.";
  const contestErrorMessage =
    "* É necesario um cargo, um concurso ou um estado e cidade para o alerta.";
  const stateErrorMessage =
    "* É necesario um cargo, um concurso ou um estado e cidade para o alerta.";
  const cityErrorMessage = "* Por favor preencha uma cidade deste estado";

  // Variáveis que controlam a exibição das listas de estado e cidade
  const [showListOffice, setShowListOffice] = useState(false);
  const [showListContest, setShowListContest] = useState(false);
  const [showListState, setShowListState] = useState(false);
  const [showListCity, setShowListCity] = useState(false);

  // Variáveis que controlam o conteúdo da lista exibida
  const [officeListContent, setOfficeListContent] = useState([]);
  const [contestListContent, setContestListContent] = useState([]);
  const [stateListContent, setStateListContent] = useState(estadosBrasil);
  const [cityListContent, setCityListContent] = useState([]);

  // Lógica de pesquisa em campos do formulário
  const [filteredOffice, setFilteredOffice] = useState([]);
  const [filteredContest, setFilteredContest] = useState([]);
  const [filteredState, setFilteredState] = useState([]);
  const [filteredCity, setFilteredCity] = useState([]);

  const [searchOpenOffice, setSearchOpenOffice] = useState(false);
  const [searchOpenContest, setSearchOpenContest] = useState(false);
  const [searchOpenCity, setSearchOpenCity] = useState(false);

  // Variável que segura os dados do cliente
  const user = useSelector((state) => state.auth.user);
  const [userData, setUserData] = useState(null);

  // Variável para vizualização do formulário
  const [showForm, setShowForm] = useState(tagAlerta ? true : false);

  // Variável que controla liberar a criação de acordo com limite máximo de cards
  const [canCreate, setCanCreate] = useState(true);

  useEffect(() => {
    if (userData) {
      if (userData.total_alerts) {
        if (userData.total_alerts >= 2) {
          setShowForm(false);
          setCanCreate(false);
        } else {
          setCanCreate(true);
        }
      }
    }
  }, [userData, recarregar]);
  // Requisição para pegar os dados do usuário
  useEffect(() => {
    async function dataGet(email) {
      const response = await fetch(`${URL}/user/users/?emails=${email}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      const data = await response.json();

      return data;
    }

    if (!user) {
      return;
    } else {
      dataGet(user.email).then((data) => {
        setUserData(data.results[0]);
      });
    }
  }, [user, Token, URL, recarregar]);

  useEffect(() => {
    if (searchOpenOffice) {
      if (office === "") {
        setFilteredOffice(officeListContent);
      } else {
        setFilteredOffice(
          officeListContent.filter((city) =>
            city.toLowerCase().includes(office.toLowerCase())
          )
        );
      }
    }
  }, [office, officeListContent, searchOpenOffice]);

  useEffect(() => {
    if (searchOpenContest) {
      if (contest === "") {
        setFilteredContest(contestListContent);
      } else {
        setFilteredContest(
          contestListContent.filter((city) =>
            city.toLowerCase().includes(contest.toLowerCase())
          )
        );
      }
    }
  }, [contest, contestListContent, searchOpenContest]);

  useEffect(() => {
    if (state === "") {
      setFilteredState(stateListContent);
    } else {
      setFilteredState(
        stateListContent.filter((city) =>
          city.toLowerCase().includes(state.toLowerCase())
        )
      );
    }
  }, [state, stateListContent]);

  useEffect(() => {
    if (searchOpenCity) {
      if (city === "") {
        setFilteredCity(cityListContent);
      } else {
        setFilteredCity(
          cityListContent.filter((e) =>
            e.toLowerCase().includes(city.toLowerCase())
          )
        );
      }
    }
  }, [city, searchOpenCity]);

  useEffect(() => {
    setUrlAtual("/");
  }, [setUrlAtual]);

  // Controle de remoção de lista ao clicar fora
  const refOffice = useRef(null);
  const refContest = useRef(null);
  const refState = useRef(null);
  const refCity = useRef(null);

  const officeOutside = (event) => {
    if (refOffice.current && !refOffice.current.contains(event.target)) {
      setShowListOffice(false);
    }
  };
  const contestOutside = (event) => {
    if (refContest.current && !refContest.current.contains(event.target)) {
      setShowListContest(false);
    }
  };

  const stateOutside = (event) => {
    if (refState.current && !refState.current.contains(event.target)) {
      setShowListState(false);
    }
  };
  const cityOutside = (event) => {
    if (refCity.current && !refCity.current.contains(event.target)) {
      setShowListCity(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", officeOutside);
    return () => {
      document.removeEventListener("mousedown", officeOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", contestOutside);
    return () => {
      document.removeEventListener("mousedown", contestOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", stateOutside);
    return () => {
      document.removeEventListener("mousedown", stateOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", cityOutside);
    return () => {
      document.removeEventListener("mousedown", cityOutside);
    };
  }, []);

  // Função que é ativada ao submeter o formulário
  function handleSubmit(e) {
    e.preventDefault();
    if (name === "") {
      setNameError(true);
      return;
    }
    if (state && !city) {
      setCityError(true);
      return;
    }
    if (!office && !contest && !state && !city) {
      setOfficeError(true);
      setContestError(true);
      setStateError(true);
      return;
    }

    const data = {
      name: name,
      user_id: userData.id,
      contest_tag_name: contest,
      location_name: city,
      office_tag_name: office,
    };

    fetch(`${URL}/alerts/alerts/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          // Inserir aqui comportamento de sucesso de envio do formulário

          // recarregar o componente
          setRecarregar(!recarregar);
        }
        return res.json();
      })
      .catch((error) => console.error("Error:", error));

    // Limpar os campos do formulário
    setName("");
    setOffice("");
    setContest("");
    setState("");
    setCity("");

    // Limpar os erros
    setNameError(false);
    setOfficeError(false);
    setContestError(false);
    setStateError(false);
    setCityError(false);
  }

  // Efeitos que ativam assim que o componente renderiza
  useEffect(() => {
    async function listRequisition(endpoint, type) {
      await fetch(endpoint, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "office") {
            setOfficeListContent(response.map((item) => item.name));
          }
          if (type === "contest") {
            setContestListContent(response.map((item) => item.name));
          }
        })
        .then(() => {
          if (type === "office") {
            setSearchOpenOffice(true);
          }
          if (type === "contest") {
            setSearchOpenContest(true);
          }
        });
    }

    listRequisition(`${URL}/office/top-tags/`, "office");
    listRequisition(`${URL}/contest/top-tags/`, "contest");
  }, [Token, URL]);

  // Efeitos que ativam quando o estado é selecionado
  useEffect(() => {
    async function getListContentByState(endpoint) {
      await fetch(endpoint, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setCityListContent(response.results.map((item) => item.name));
          setFilteredCity(response.results.map((item) => item.name));
        })
        .then(() => {
          setSearchOpenCity(true);
        });
    }

    function findStateShortName(state) {
      const index = estadosBrasil.indexOf(state);

      if (index !== -1) {
        return siglasEstados[index];
      }
    }

    let stateGet = findStateShortName(state);

    getListContentByState(
      `${URL}/location/location/?limit=1000&state=${stateGet}`
    );
  }, [state, URL, Token]);

  //  Limpar cidade após estado ser excluído
  useEffect(() => {
    if (state === "") {
      setCity("");
    }
  }, [state]);

  return (
    <StyledAlerta>
      {/* 1 Seção */}
      {userData?.subscription_active ? (
        <section className="alertFlexTittleAndButtonMain">
          <div className="alertFlexTittleAndButtonMainTittle">
            <h2>Alertas</h2>
            <p>
              Crie alertas para os <span>cargos, concursos e localidades</span>{" "}
              que você procura para ser{" "}
              <span>
                notificado por e-mail assim que o concurso estiver na Alô
                Concursos.
              </span>
            </p>
            {!canCreate && (
              <p style={{ color: "red" }}>
                Você atingiu o máximo de alertas criados, por favor remova um
                alerta para criar um novo ou edite um já existente.
              </p>
            )}
          </div>

          {!canCreate ? (
            <button
              style={{
                background: "#C5C8DB",
                cursor: "not-allowed",
              }}
              disabled
            >
              Criar alerta
            </button>
          ) : (
            <button onClick={() => setShowForm(true)}>Criar alerta</button>
          )}
        </section>
      ) : (
        <section className="alertFlexTittleAndButtonMain">
          <div className="alertFlexTittleAndButtonMainTittle">
            <h2>Alertas</h2>
            <p>
              <span>Você precisa ser um</span>{" "}
              <Link to="/planos">assinante Premium ou Premium +</Link> para
              poder usar o Sistema de Alertas.
            </p>
          </div>
          <Link to="/planos">
            <button style={{ display: "block" }}>Assinar</button>
          </Link>
        </section>
      )}
      {/* 2 Seção */}
      {userData?.subscription_active && showForm && (
        <section className="alertBoxForm">
          <div className="alertBoxFormTittle">
            <h2>Criar alerta</h2>
            <p>
              Crie alertas para os <span>cargos, concursos e localidades</span>{" "}
              que você procura para ser{" "}
              <span>
                notificado por e-mail assim que o concurso estiver na Alô
                Concursos.
              </span>
            </p>
          </div>
          {/* form para quem é pago */}
          <form onSubmit={handleSubmit}>
            <FormLabelInputAlert
              tittle={"Nome"}
              error={nameError}
              setError={setNameError}
              errorMessage={nameErrorMessage}
              valueVariable={name}
              setValueVariable={setName}
              description={"Adicionar nome do alerta"}
              placeholder={"Ex: Administração Municipal RJ"}
            />
            <hr />
            <div className="flexInputs">
              <FormLabelInputAlert
                tittle={"Cargo"}
                refName={refOffice}
                showList={showListOffice}
                setShowList={setShowListOffice}
                listContent={filteredOffice}
                setListContent={setOfficeListContent}
                error={officeError}
                setError={setOfficeError}
                errorMessage={officeErrorMessage}
                valueVariable={office}
                setValueVariable={setOffice}
                description={"Etiqueta de cargo"}
                placeholder={"Ex: Medicina"}
              />
              <FormLabelInputAlert
                tittle={"Concurso"}
                refName={refContest}
                showList={showListContest}
                setShowList={setShowListContest}
                listContent={filteredContest}
                setListContent={setContestListContent}
                error={contestError}
                setError={setContestError}
                errorMessage={contestErrorMessage}
                valueVariable={contest}
                setValueVariable={setContest}
                description={"Etiqueta de concurso"}
                placeholder={"Ex: Federal"}
              />
            </div>
            <hr />
            <div className="flexInputs">
              <FormLabelInputAlert
                tittle={"Estado"}
                setCity={setCity}
                refName={refState}
                showList={showListState}
                setShowList={setShowListState}
                listContent={filteredState}
                setListContent={setStateListContent}
                error={stateError}
                setError={setStateError}
                errorMessage={stateErrorMessage}
                valueVariable={state}
                setValueVariable={setState}
                description={"Adicionar estado"}
                placeholder={"Ex: Minas Gerais"}
              />
              <FormLabelInputAlert
                tittle={"Cidade"}
                refName={refCity}
                showList={showListCity}
                setShowList={setShowListCity}
                listContent={filteredCity}
                setListContent={setCityListContent}
                disabledCondition={!state}
                error={cityError}
                setError={setCityError}
                errorMessage={cityErrorMessage}
                valueVariable={city}
                setValueVariable={setCity}
                description={"Adicionar cidade"}
                placeholder={"Ex: Juiz de Fora"}
              />
            </div>
            <hr />
            <div className="flexButtons">
              <button className="salvar">
                <img src={disk} alt="ícone de salvar" />
                <p>Salvar</p>
              </button>
              <button onClick={() => setShowForm(false)} className="fechar">
                <img src={cross} alt="ícone de fechar" />
                <p>Fechar</p>
              </button>
            </div>
          </form>
        </section>
      )}
      {/* 3 Seção - Alertas */}
      {userData?.subscription_active && (
        <section className="alertCreatedBox">
          <h2>Alertas criados</h2>
          {userData &&
            userData.alerts.map((alert, index) => (
              <AlertCreatedCard
                {...alert}
                recarregar={recarregar}
                setRecarregar={setRecarregar}
                key={index}
              />
            ))}
        </section>
      )}
    </StyledAlerta>
  );
}

export default Alerta;
