import React from "react";
import { StyledAlertMiniBox } from "../styles/AlertMiniBox";

function AlertMiniBox({ tittle, subTittle }) {
  return (
    <StyledAlertMiniBox>
      <span>{tittle}</span>
      <p>{subTittle}</p>
    </StyledAlertMiniBox>
  );
}

export default AlertMiniBox;
