import React, { useEffect, useState } from "react";
import { StyledSucessPayment } from "../styles/SucessPayment";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function SucessPayment() {
  const [payment, setPayment] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;
  const getSessionIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("session_id");
  };

  useEffect(() => {
    async function requisicao(apiLink) {
      const res = await fetch(apiLink, {
        method: "POST",
        headers: {
          Authorization: `Token ${Token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: sessionId,
        }),
      });

      if (res.ok) {
        const data = await res.json();
        setPayment(data.payment_status);
      } else {
        console.error("Erro na requisição:", res.statusText);
      }
    }
    const sessionId = getSessionIdFromUrl();
    const queryForApi = `${URL}/api-stripe/verify-session`;

    if (!sessionId) {
      navigate("/");
    } else {
      requisicao(queryForApi);
    }
  }, []);

  useEffect(() => {
    if (payment === "unpaid") {
      navigate("/");
    }
  }, [payment, navigate]);

  const pageTittle = "Bem vindo ao Premium - Alô Concursos";
  const pageDescription = "Bem vindo a Alô Concursos Premium.";
  const pageLink = `https://www.aloconcursos.com/sucesso`;

  return (
    <StyledSucessPayment>
      <Helmet>
        <title>{pageTittle}</title>
        <meta property="og:title" content={pageTittle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageLink} />
        <meta name="twitter:title" content={pageTittle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:url" content={pageLink} />
      </Helmet>
      {payment === "paid" && (
        <div className="popUpSuccess">
          <svg
            onClick={() => navigate("/conta")}
            className="xIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <g clipPath="url(#clip0_3413_1149)">
              <path
                d="M8.88393 8.50027L15.8169 1.56722C16.061 1.32313 16.061 0.927376 15.8169 0.683314C15.5729 0.439252 15.1771 0.439221 14.933 0.683314L7.99999 7.61636L1.06697 0.683314C0.822881 0.439221 0.427132 0.439221 0.18307 0.683314C-0.0609921 0.927407 -0.0610233 1.32316 0.18307 1.56722L7.11609 8.50024L0.18307 15.4333C-0.0610233 15.6774 -0.0610233 16.0731 0.18307 16.3172C0.305101 16.4392 0.465069 16.5002 0.625038 16.5002C0.785006 16.5002 0.944944 16.4392 1.06701 16.3172L7.99999 9.38417L14.933 16.3172C15.055 16.4392 15.215 16.5002 15.375 16.5002C15.5349 16.5002 15.6949 16.4392 15.8169 16.3172C16.061 16.0731 16.061 15.6773 15.8169 15.4333L8.88393 8.50027Z"
                fill="#1B2CC1"
              />
            </g>
            <defs>
              <clipPath id="clip0_3413_1149">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <div className="diamondAndText">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="97"
              viewBox="0 0 96 97"
              fill="none"
            >
              <g clipPath="url(#clip0_3413_1154)">
                <path
                  d="M96 36.5C95.4819 39.4044 94.3277 42.1582 92.62 44.564L57.488 91.844C56.3742 93.283 54.9477 94.4498 53.3164 95.2562C51.6852 96.0627 49.8919 96.4875 48.0722 96.4987C46.2526 96.5099 44.4542 96.1071 42.8132 95.3208C41.1721 94.5344 39.7314 93.3852 38.6 91.96L3.296 43.7C1.79632 41.527 0.737651 39.0807 0.18 36.5H25.252L44.264 85.936C44.5533 86.6923 45.0654 87.343 45.7324 87.8021C46.3995 88.2611 47.1902 88.5069 48 88.5069C48.8098 88.5069 49.6005 88.2611 50.2676 87.8021C50.9346 87.343 51.4467 86.6923 51.736 85.936L70.748 36.5H96ZM70.8 28.5H95.828C95.2195 25.7936 94.0519 23.2444 92.4 21.016L82.168 7.20001C80.6863 5.12903 78.7323 3.44101 76.4681 2.27584C74.2038 1.11067 71.6945 0.501907 69.148 0.500008H60.348L70.8 28.5ZM44.432 0.500008L33.796 28.5H62.244L51.82 0.500008H44.432ZM25.24 28.5L35.872 0.500008H26.584C24.0591 0.497522 21.5697 1.09411 19.3202 2.24075C17.0707 3.3874 15.1254 5.05139 13.644 7.09601L3.752 20.064C1.85922 22.5441 0.574178 25.4334 0 28.5H25.24ZM62.176 36.5H33.824L48 73.356L62.176 36.5Z"
                  fill="#5FCE1D"
                />
              </g>
              <defs>
                <clipPath id="clip0_3413_1154">
                  <rect
                    width="96"
                    height="96"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="textAndTittle">
              <h1>Bem-vindo ao próximo nível!</h1>
              <p>
                Você agora tem acesso{" "}
                <span>a todas as funcionalidades do plano Premium Plus</span> da
                Alô Concursos! 🎉 <br />
                <br /> Explore nossos filtros avançados e fique à frente na
                busca pelas melhores oportunidades. <br />
                <br />
                Sucesso na sua jornada de estudos! 🤓
              </p>
            </div>
          </div>
          <Link to={"/"}>
            <button>Página inicial</button>
          </Link>
        </div>
      )}
    </StyledSucessPayment>
  );
}

export default SucessPayment;
