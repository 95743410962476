import styled from "styled-components";

export const StyledAlertCreatedCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 32px;
  border-radius: 8px;
  background: var(--Supporting-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

  .tittle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h3 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
    p {
      color: var(--Grey-500, #606780);
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    button {
      position: absolute;
      right: 2rem;
      top: 2rem;
      cursor: pointer;
      background-color: transparent;
      border: none;
      transform: rotate(90deg);
    }
  }
  h2 {
    color: var(--Grey-700, #33394d);
    font-family: "Work Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
  }
  .flexAlertCardShowOrNot {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    .flexAlertCardShowOrNotFlexMiniBox {
      display: flex;
      justify-content: space-between;
      div {
        width: 214px;
      }
      /* justify-content: space-between; */
    }
    .flexAlertCardShowOrNotFlexButtons {
      display: flex;
      flex-direction: column;
      gap: 32px;
      button {
        width: 128px;
        display: flex;
        padding: 12px 24px;
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
        cursor: pointer;
        background-color: transparent;
      }
      .delete {
        color: var(--Error-600, #ba1a22);
        border: 1px solid var(--Error-600, #ba1a22);
      }
      .edit {
        color: var(--Primary-500, #1b2cc1);
        border: 1px solid var(--Primary-500, #1b2cc1);
      }
      .concluir {
        background-color: var(--Primary-500, #1b2cc1);
        color: #fff;
      }
    }
  }
  .inputAlertNameCard {
    min-width: 214px;
    width: calc(60% - 214px);
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid var(--Primary-500, #1b2cc1);
    background: var(--Grey-50, #f7f8ff);
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  @media (max-width: 1310px) {
    .flexAlertCardShowOrNot {
      .flexAlertCardShowOrNotFlexMiniBox {
        flex-direction: column;
        gap: 3.2rem;
      }
    }
  }
  @media (max-width: 418px) {
    .tittle {
      h3 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      p {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .flexAlertCardShowOrNot {
      h2 {
        font-size: 20px;
      }
    }
  }
`;
