import React from "react";
import { StyledFormLabelInputAlert } from "../styles/FormLabelInputAlert";

function FormLabelInputAlert({
  tittle,
  description,
  placeholder,
  valueVariable,
  setValueVariable,
  error,
  setError,
  errorMessage,
  disabledCondition,
  showList,
  setShowList,
  listContent,
  refName,
  setCity,
}) {
  function handleChoose(item) {
    setValueVariable(item);
    setShowList(false);
    if (tittle === "Estado") {
      setCity([]);
    }
  }
  function onFocusInput() {
    if (setShowList) {
      setShowList(true);
    }
    setError(false);
  }

  return (
    <StyledFormLabelInputAlert>
      <label htmlFor="nome">{tittle}</label>
      <span>{description}</span>
      <div className="inputAndError" ref={refName}>
        <input
          style={
            error
              ? { border: "1px solid #DE4C49" }
              : { border: "1px solid #1b2cc1" }
          }
          value={valueVariable}
          onChange={(e) => setValueVariable(e.target.value)}
          onFocus={onFocusInput}
          placeholder={placeholder}
          type="text"
          disabled={disabledCondition}
        />
        {error && <span>{errorMessage}</span>}
        {showList && (
          <ul>
            {listContent.map((item, index) => (
              <li onClick={() => handleChoose(item)} key={index}>
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
    </StyledFormLabelInputAlert>
  );
}

export default FormLabelInputAlert;
